<template>
  <q-form ref="editForm">
    <!-- 가동전점검 상세 -->
    <c-card title="LBL0002109" class="cardClassDetailForm no-margin">
      <template slot="card-button">
        <q-btn-group outline >
          <q-chip
            v-if="(!preStartupCheck.equipments || preStartupCheck.equipments.length === 0) && Boolean(param.sopPrestartupCheckId)"
            class="no-border"
            style="margin-right:5px !important" 
            square outline
            color="red" 
            text-color="white" 
            icon="report" 
            size="12px" >
            <!-- 가동전점검표, 보고서는 하나 이상의 설비 점검표가 만들어져야 이용 가능합니다. -->
            {{$label('LBL0002110')}}
          </q-chip>
          <!-- 가동전점검표 -->
          <c-btn 
            v-show="Boolean(param.sopPrestartupCheckId)" 
            :disabled="!preStartupCheck.equipments || preStartupCheck.equipments.length === 0"
            label="LBL0002111" 
            icon="print"
            @btnClicked="checklistPrint" />
          <!-- 가동전안전점검보고서 -->
          <c-btn 
            v-show="Boolean(param.sopPrestartupCheckId)" 
            :disabled="!preStartupCheck.equipments || preStartupCheck.equipments.length === 0"
            label="LBL0002112" 
            icon="print"
            @btnClicked="checklistReportPrint" />
          <!-- 삭제 -->
          <c-btn v-show="editInfo && !disabled && Boolean(param.sopPrestartupCheckId)" label="LBLREMOVE" icon="remove" @btnClicked="removePreStartupCheck" />
          <!-- 완료 -->
          <c-btn 
            v-show="editInfo && !disabled && Boolean(param.sopPrestartupCheckId)" 
            :isSubmit="isComplete"
            :url="saveUrl"
            :param="preStartupCheck"
            mappingType="PUT"
            label="LBLCOMPLETE" 
            icon="check"
            @beforeAction="completePreStartupCheck"
            @btnCallback="completeCallback" />
          <!-- 저장 -->
          <c-btn 
            v-show="editInfo && !disabled" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="preStartupCheck"
            :mappingType="mappingType"
            label="LBLSAVE" 
            icon="save"
            @beforeAction="savePreStartupCheck"
            @btnCallback="saveCallback" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-9">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
              <!-- 점검명 -->
              <c-text
                :required="true" 
                :editable="editInfo"
                :disabled="disabled"
                label="LBL0002103"
                name="checkName"
                v-model="preStartupCheck.checkName">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-2">
              <!-- 설비상태 -->
              <c-select
                :required="true" 
                :editable="editInfo"
                :disabled="disabled"
                codeGroupCd="PSR_EQUIPMENT_STATUS_CD"
                type="edit"
                itemText="codeName"
                itemValue="code"
                name="psrEquipmentStatusCd"
                label="LBL0002100"
                v-model="preStartupCheck.psrEquipmentStatusCd"
              ></c-select>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-2">
              <!-- 점검부서 -->
              <c-dept 
                :required="true" 
                :editable="editInfo"
                :disabled="disabled"
                type="edit" 
                label="LBL0002101" 
                name="checkDeptCd" 
                v-model="preStartupCheck.checkDeptCd" />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-2">
              <!-- 점검총책임자 -->
              <c-field 
                :required="true" 
                :editable="editInfo"
                :disabled="disabled"
                :plantCd="preStartupCheck.plantCd"
                type="user" 
                label="LBL0002113" 
                name="checkCancarrierId" 
                v-model="preStartupCheck.checkCancarrierId" />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
              <!-- 점검목적 -->
              <c-textarea
                :editable="editInfo"
                :disabled="disabled"
                label="LBL0002114"
                name="checkPurpose"
                v-model="preStartupCheck.checkPurpose">
              </c-textarea>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-8 col-lg-4">
              <!-- MOC번호 -->
              <c-moc 
                :editable="editInfo"
                :disabled="disabled"
                :isSubmit="saveCallData"
                :document.sync="preStartupCheck"
                :documentId="param.sopPrestartupCheckId"
                documentTitle="checkName"
                mocRelatedTaskCd="RT00000015"
                label="LBLMOCNO"
                name="sopMocId"
                v-model="preStartupCheck.sopMocId"
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-2">
              <c-plant 
                :required="true" 
                :disabled="disabled"
                :editable="editInfo" 
                type="edit" 
                name="plantCd" 
                v-model="preStartupCheck.plantCd" />
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3">
          <div class="row">
            <div class="col-12">
              <!-- 점검결과종합 -->
              <c-textarea
                :editable="editInfo"
                :disabled="disabled"
                :rows="3"
                label="LBL0002115"
                name="checkResultTotal"
                v-model="preStartupCheck.checkResultTotal">
              </c-textarea>
            </div>
          </div>
        </div>
      </template>
    </c-card>
    <c-tab
      class="q-mt-sm preStartup-tab-no-padding"
      type="tabcard"
      align="left"
      :height="tabHeight"
      :editable="editInfo"
      :tabItems.sync="preStartupCheck.equipments"
      :inlineLabel="true"
      :addTab="editInfo && !disabled"
      :removeTab="editInfo && !disabled"
      removeConditionCol="checkCompleteFlag"
      removeConditionVal="Y"
      v-model="tab"
      @addTab="addTab"
      @removeTab="removeTab"
      @tabClick="tabClick"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :key="tab.key"
          :param="param"
          :preStartupCheck.sync="preStartupCheck"
          :equipmentCd="tab.equipmentCd"
          :research.sync="research"
        />
      </template>
    </c-tab>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'pre-startup-review-detail',
  props: {
    param: {
      type: Object,
      default: () => ({
        sopPrestartupCheckId: '',
        psrCheckStatusCd: '',
        isSearch: false,
      }),
    },
    preStartupCheck: {
      type: Object,
      default: () => ({
        sopPrestartupCheckId: '',  // 가동전점검 일련 번호
        plantCd: null,  // 사업장코드
        checkName: '',  // 점검명
        checkPurpose: '',  // 점검 목적
        psrEquipmentStatusCd: null,  // 설비 상태
        checkDeptCd: null,  // 점검부서
        sopMocId: '',  // MOC 일련번호
        checkCancarrierId: '',  // 점검 책임자
        checkResultTotal: '',  // 점검결과 종합
        psrCheckStatusCd: '',  // 진행 단계
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        equipments: [],
        deleteEquipments: [],
      }),
    },
    research: {
      type: Object,
      default: () => ({
        impr: '',
        item: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      tab: '',
      tabItems: [],
      editable: true,
      detailUrl: '',
      equipListUrl: '',
      saveUrl: '',
      deleteUrl: '',
      checklistPrintUrl: '',
      checklistReportPrintUrl: '',
      deleteEquipUrl: '',
      isSave: false,
      isComplete: false,
      mappingType: 'POST',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        param: {},
        closeCallback: null,
      },
      saveCallData: '',
    };
  },
  watch: {
    'research.item'() {
      this.getEquips();
    }
  },
  computed: {
    editInfo() {
      return this.editable && !this.param.isSearch
    },
    disabled() {
      // 점검완료인 경우 비활성화
      return this.preStartupCheck.psrCheckStatusCd === 'PCSC000005';
    },
    tabHeight() {
      return String(this.contentHeight - 300) + 'px';
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sop.psr.check.get.url;
      this.equipListUrl = selectConfig.sop.psr.check.equips.url;
      this.saveUrl = transactionConfig.sop.psr.check.insert.url
      this.deleteUrl = transactionConfig.sop.psr.check.delete.url
      this.checklistPrintUrl = selectConfig.sop.psr.check.checklistPrint.url
      this.checklistReportPrintUrl = selectConfig.sop.psr.check.checklistReportPrint.url
      this.deleteEquipUrl = transactionConfig.sop.psr.check.equipment.delete.url
      // code setting
      // list setting
      this.getPreStartupCheck();
    },
    getPreStartupCheck() {
      if (this.param.sopPrestartupCheckId) {
        this.$http.url = this.$format(this.detailUrl, this.param.sopPrestartupCheckId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.preStartupCheck, _result.data)
          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data)
          // this.preStartupCheck = _result.data;
          this.param.psrCheckStatusCd = this.$_.clone(this.preStartupCheck.psrCheckStatusCd)
          // this.$_.extend(this.preStartupCheck, _result.data);

          if (this.preStartupCheck.equipments && this.preStartupCheck.equipments.length > 0) {
            this.tab = this.preStartupCheck.equipments[0].equipmentCd;
            this.$_.forEach(this.preStartupCheck.equipments, item => {
              item.name = item.equipmentCd;
              item.label = item.equipmentName;
              item.icon = 'precision_manufacturing'
              item.component = () => import(`${'./preStartupFacility.vue'}`)
              item.key = uid()
              // this.tabItems.push({ name: item.equipmentCd, icon: 'precision_manufacturing', label: item.equipmentName, component: () => import(`${'./preStartupFacility.vue'}`) })
            })
          }

        },);
      }
    },
    getEquips() {
      this.$http.url = this.equipListUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        sopPrestartupCheckId: this.param.sopPrestartupCheckId,
      };
      this.$http.request((_result) => {
        this.tab = _result.data[0].equipmentCd;
        this.$_.forEach(_result.data, item => {
          item.name = item.equipmentCd;
          item.label = item.equipmentName;
          item.icon = 'precision_manufacturing'
          item.component = () => import(`${'./preStartupFacility.vue'}`)
          item.key = uid()
        })
        this.$set(this.preStartupCheck, 'equipments', _result.data)
      },);
    },
    addTab() {
      this.popupOptions.title = 'LBL0000521'; // 설비 검색
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/equip/equipPop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeFacilityPopup;
    },
    closeFacilityPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        data = this.$_.orderBy(data, 'equipmentCd', 'asc')
        if (!this.preStartupCheck.equipments || this.preStartupCheck.equipments.length === 0) {
          this.tab = data[0].equipmentCd;
        }
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.preStartupCheck.equipments, { name: item.equipmentCd }) === -1) {
            this.preStartupCheck.equipments.push({
              name: item.equipmentCd,
              label: item.equipmentName,
              icon: 'precision_manufacturing',
              component: () => import(`${'./preStartupFacility.vue'}`),
              sopPrestartupCheckId: '',  // 가동전점검 일련 번호
              equipmentCd: item.equipmentCd,  // 설비코드
              equipmentName: item.equipmentName,  // 설비명
              equipmentTypeCd: item.equipmentTypeCd, // 설비 분류(상하위구조)
              equipmentTypeName: item.equipmentTypeName, // 설비 분류명(상하위구조)
              checkUserId: '',  // 점검 책임자
              checkScheduleDate: '',  // 점검 예정일
              checkDate: '',  // 점검일
              checkResultTotal: '',  // 점검 결과 종합
              checkCompleteFlag: 'N',  // 점검 완료 여부
              regUserId: '',  // 등록자 ID
              chgUserId: '',  // 수정자 ID
              checkTypes: [], // 점검유형
              checkItems: [], // 점검항목
              editFlag: 'C',
            })
          }
        })
      }
    },
    removeTab(tab) {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        // 삭제하실 경우 해당 공정에 해당하는\n\r점검정보 및 결과목록도 변경됩니다.\n\r진행하시겠습니까?
        message: 'MSG0000600',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          if (!this.preStartupCheck.deleteEquipments) {
            this.preStartupCheck.deleteEquipments = [];
          }
          if (this.$_.findIndex(this.preStartupCheck.deleteEquipments, tab) === -1 && tab.editFlag !== 'C') {
            this.preStartupCheck.deleteEquipments.push(tab)
          }
          
          this.$http.url = this.deleteEquipUrl;
          this.$http.type = 'DELETE';
          this.$http.param = {
            data: Object.values(this.preStartupCheck.deleteEquipments)
          };
          this.$http.request(() => {
            // this.$set(this.preStartupCheck.equipments, this.$_.findIndex(this.preStartupCheck.equipments, tab), null)
            this.preStartupCheck.equipments.splice(this.$_.findIndex(this.preStartupCheck.equipments, tab), 1)
            if (this.preStartupCheck.equipments && this.preStartupCheck.equipments.length > 0) {
              this.tab = this.preStartupCheck.equipments[0].name
            }
            this.preStartupCheck.deleteEquipments = [];
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    tabClick(tab) {
      this.tab = tab.name;
    },
    savePreStartupCheck() {
      if (this.param.sopPrestartupCheckId) {
        this.saveUrl = transactionConfig.sop.psr.check.update.url
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.sop.psr.check.insert.url
        this.mappingType = 'POST';
        this.preStartupCheck.psrCheckStatusCd = 'PCSC000001' // 점검중
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.preStartupCheck.regUserId = this.$store.getters.user.userId
              this.preStartupCheck.chgUserId = this.$store.getters.user.userId

              if (this.preStartupCheck.equipments && this.preStartupCheck.equipments.length > 0) {
                this.preStartupCheck.equipments = this.$_.filter(this.preStartupCheck.equipments, null)
              }
              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      this.param.sopPrestartupCheckId = result.data
      this.saveCallData = uid();
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getPreStartupCheck();
    },
    completePreStartupCheck() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          if (!this.preStartupCheck.checkResultTotal) {
            window.getApp.$emit('ALERT', {
              title: 'LBLGUIDE', // 안내
              message: 'MSG0000601', // 점검결과종합이 입력되지 않았습니다.
              type: 'warning', // success / info / warning / error
            });
          } else if (!this.preStartupCheck.equipments || this.preStartupCheck.equipments.length === 0) {
            window.getApp.$emit('ALERT', {
              title: 'LBLGUIDE', // 안내
              message: 'MSG0000602', // 하나 이상의 점검표가 만들어져야 합니다.
              type: 'warning', // success / info / warning / error
            });
          } else {
            let isEquipComplete = true;
            let isImprComplete = true;
            let message = 'MSG0000603'; // 점검완료하시겠습니까?
            this.$_.forEach(this.preStartupCheck.equipments, equip => {
              if (equip.checkCompleteFlag !== 'Y') {
                isEquipComplete = false
                return false;
              }

              this.$_.forEach(equip.checkItems, checkItem => {
                if (this.$_.findIndex(checkItem.improves, impr => {
                  return impr.ibmStepCd !== 'IS00000020'
                }) > -1) {
                  isImprComplete = false;
                  return false;
                }
              })
              if (!isImprComplete) {
                return false;
              }
            })

            if (!isEquipComplete) {
              window.getApp.$emit('ALERT', {
                title: 'LBLGUIDE', // 안내
                message: 'MSG0000604', // 점검이 완료되지 않은 설비가 있습니다.
                type: 'warning', // success / info / warning / error
              });
              return;
            }
            if (!isImprComplete) {
              message = 'MSG0000605' // 개선 진행중인 항목이 존재합니다.\n\r점검완료하시겠습니까?
            }
            window.getApp.$emit('CONFIRM', {
              title: 'LBLCONFIRM',
              message: message,
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.preStartupCheck.chgUserId = this.$store.getters.user.userId
                this.preStartupCheck.psrCheckStatusCd = 'PCSC000005'
  
                if (this.preStartupCheck.equipments && this.preStartupCheck.equipments.length > 0) {
                  this.preStartupCheck.equipments = this.$_.filter(this.preStartupCheck.equipments, null)
                }
                this.isComplete = !this.isComplete
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          }
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    completeCallback(result) {
      this.param.sopPrestartupCheckId = result.data
      this.saveCallData = uid();
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getPreStartupCheck();
    },
    removePreStartupCheck() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.param.sopPrestartupCheckId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    checklistPrint() {
      let thisVue = this;
      this.$http.url = this.$format(this.checklistPrintUrl, this.param.sopPrestartupCheckId);
      this.$http.type = 'GET';
      this.$http.request(
        _result => {
          let fileOrgNm = '가동전점검표_' + this.preStartupCheck.checkName + '_'  + this.$comm.getToday() + '.xlsx';
          let blob = thisVue.$comm.base64ToBlob(_result.data);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileOrgNm);
          } else {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileOrgNm;
            link.click();
          }
        }, () => { }
      );
    },
    checklistReportPrint() {
      let thisVue = this;
      this.$http.url = this.$format(this.checklistReportPrintUrl, this.param.sopPrestartupCheckId);
      this.$http.type = 'GET';
      this.$http.request(
        _result => {
          let fileOrgNm = '가동전 안전점검 보고서_' + this.preStartupCheck.checkName + '_'  + this.$comm.getToday() + '.xlsx';
          let blob = thisVue.$comm.base64ToBlob(_result.data);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileOrgNm);
          } else {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileOrgNm;
            link.click();
          }
        }, () => { }
      );
    },
  }
};
</script>
<style lang="sass">
.preStartup-tab-no-padding
  .q-tab-panel
    padding: 3px !important
    .q-table__container
      margin: 0px !important
</style>